<template>
  <div class="onboarding-wrapper">
    <router-view></router-view>

    <div v-if="state.showProgress" class="progress-section">
      <div v-if="state.showBicky" class="bicky-progress">
        <div class="prefix"></div>

        <div class="progress-bar">
          <div ref="bickyPin" class="progress-pin">
            <tooltip
              :triggers="['focus']"
              :is-show="state.showBickyTip"
              :auto-hide="false"
              :placement="state.tooltipPlacement"
              :distance="state.tooltipDistance"
              container=".progress-bar"
            >
              <template #button>
                <video
                  class="bicky"
                  playsinline
                  autoplay
                  loop
                  muted
                  poster="/assets/images/alternative-images/bicky.png"
                >
                  <source
                    src="/assets/images/onboarding/bicky.webm"
                    type="video/webm"
                  />
                  <source
                    src="/assets/images/onboarding/bicky.mp4"
                    type="video/mp4"
                  />
                </video>
              </template>
              <template #tooltip>
                <p class="b-text-2 tooltip-text" v-html="state.bickyTip"></p>
              </template>
            </tooltip>
          </div>
        </div>
      </div>

      <div class="deco">
        <span class="start e-text-1" :class="{ active: !state.lastStep }">
          출발
        </span>

        <div class="end">
          <tooltip
            :triggers="['focus']"
            :is-show="state.showFinalTooltip"
            :auto-hide="false"
            :distance="10"
            placement="left"
            container=".deco"
          >
            <template #button>
              <span
                class="text e-text-1"
                :class="{ active: state.showFinalTooltip }"
                >도착</span
              >
            </template>
            <template #tooltip>
              <p class="b-text-2 tooltip-text" v-html="state.bickyTip"></p>
            </template>
          </tooltip>

          <img src="/assets/images/onboarding/final_flag.png" width="28" />
        </div>
      </div>

      <div class="bar">
        <div class="prefix"></div>

        <progress
          class="progress-bar"
          :value="state.percentage"
          :min="0"
          :max="100"
        ></progress>
      </div>
    </div>
  </div>
</template>

<script>
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { reactive, onBeforeMount, computed, ref, watch } from "vue";
import onBoardingConst from "@/consts/onboarding";
import Tooltip from "../../../components/console/tooltip/Tooltip";
import helper from "@/helper";

export default {
  name: "OnBoarding",
  components: { Tooltip },
  beforeRouteUpdate(to, from, next) {
    if (to.path !== from.path) {
      next();
    }
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();

    const bickyPin = ref();

    const state = reactive({
      lastStep: computed(() => {
        return store.getters["onBoardings/lastStep"];
      }),
      showBickyTip: false,
      showBicky: computed(() => {
        return (
          state.lastStep !== onBoardingConst.onBoardingSteps.approved &&
          state.lastStep !== onBoardingConst.onBoardingSteps.review &&
          state.lastStep !== onBoardingConst.onBoardingSteps.finish &&
          state.lastStep !== onBoardingConst.onBoardingSteps.channel
        );
      }),
      percentage: computed(() => {
        if (state.lastStep === onBoardingConst.onBoardingSteps.start) {
          return 20;
        } else if (state.lastStep === onBoardingConst.onBoardingSteps.profile) {
          return 50;
        } else if (
          state.lastStep === onBoardingConst.onBoardingSteps.interest
        ) {
          return 60;
        } else if (state.lastStep === onBoardingConst.onBoardingSteps.purpose) {
          return 80;
        } else if (state.lastStep === onBoardingConst.onBoardingSteps.channel) {
          return 100;
        } else {
          return 0;
        }
      }),
      tooltipPlacement: computed(() => {
        return state.percentage > 50 ? "left-start" : "right-start";
      }),
      bickyTip: computed(() => {
        if (state.lastStep === onBoardingConst.onBoardingSteps.start) {
          return `빅크와 함께할<br /><span class="text-purple-50">빅크리에이터</span>를 찾습니다!`;
        } else if (state.lastStep === onBoardingConst.onBoardingSteps.profile) {
          return helper.isMobileSize()
            ? `해당 주제에 관심있는<br /><span class="text-purple-50">팬들에게 추천</span>해드려요`
            : `콘텐츠 판매 시 해당 주제에<br />관심있는 <span class="text-purple-50">팬들에게 추천</span>해드려요`;
        } else if (
          state.lastStep === onBoardingConst.onBoardingSteps.interest
        ) {
          return helper.isMobileSize()
            ? ` 빅크리에이터를 위한 <br /><sapn class="text-purple-50">32가지 기능</sapn>을 만나보세요!`
            : `신청을 완료하고 빅크리에이터를 위한<br/><sapn class="text-purple-50">32가지 기능</span>을 만나보세요!`;
        } else if (state.lastStep === onBoardingConst.onBoardingSteps.purpose) {
          return helper.isMobileSize()
            ? `<span class="text-purple-50">활동 내역을 보여줄 콘텐츠가</span><br /><span class="text-purple-50">많을수록</span> 승인 확률이 높아져요!`
            : `<span class="text-purple-50">활동 내역을 보여줄 콘텐츠가 많을수록</span><br/>빅크리에이터가 될 확률이 높아져요!`;
        } else if (state.lastStep === onBoardingConst.onBoardingSteps.channel) {
          return `<span class="text-purple-50"> 영업일 기준 5일 이내</span>로<br />승인 여부를 알려 드릴게요! 💪`;
        } else {
          return `빅크리에이터 신청<br /><span class="text-purple-50">단 3분이면 신청 완료!</span>`;
        }
      }),
      showFinalTooltip: computed(() => {
        return state.lastStep === onBoardingConst.onBoardingSteps.channel;
      }),
      tooltipDistance: computed(() => {
        return state.percentage >= 60 ? 30 : 10;
      }),
      showProgress: computed(() => {
        return (
          state.lastStep !== onBoardingConst.onBoardingSteps.approved &&
          state.lastStep !== onBoardingConst.onBoardingSteps.review &&
          state.lastStep !== onBoardingConst.onBoardingSteps.finish
        );
      }),
    });

    watch(
      () => [state.lastStep, route.path],
      (data) => {
        let routePathArr = data[1].split("/");
        let routeName = routePathArr[routePathArr.length - 1];

        // note 온보딩 완료 후 다른 페이지로 이동 불가
        if (
          routeName !== "complete" &&
          state.lastStep === onBoardingConst.onBoardingSteps.channel
        ) {
          return router.push({ name: "console.onboarding.complete" });
        }

        if (state.showProgress) {
          updateProgress();
        }
      }
    );

    const updateProgress = () => {
      state.showBickyTip = false;

      if (state.showProgress && bickyPin.value) {
        bickyPin.value.style.left = `${state.percentage}%`;
        setTimeout(() => {
          state.showBickyTip = true;
        }, 1000);
      }
    };

    onBeforeMount(() => {
      store.dispatch("onBoardings/getOnBoardingStep").then(() => {
        if (state.lastStep === onBoardingConst.onBoardingSteps.finish) {
          return router.push({ name: "console.home" });
        }

        let stepName;

        switch (state.lastStep) {
          case onBoardingConst.onBoardingSteps.start:
            stepName = "profile";
            break;
          case onBoardingConst.onBoardingSteps.profile:
            stepName = "interest";
            break;
          case onBoardingConst.onBoardingSteps.interest:
            stepName = "purpose";
            break;
          case onBoardingConst.onBoardingSteps.purpose:
            stepName = "channel";
            break;
          case onBoardingConst.onBoardingSteps.channel:
            stepName = "complete";
            break;
          case onBoardingConst.onBoardingSteps.review:
            stepName = "review";
            break;
          case onBoardingConst.onBoardingSteps.approved:
            stepName = "approved";
            break;
          default:
            stepName = "";
            break;
        }

        if (stepName) {
          router.push({
            name: `console.onboarding.${stepName}`,
          });
        } else {
          router.push({
            name: `console.onboarding`,
          });
        }

        if (state.showProgress) {
          updateProgress();
        }
      });
    });

    const actions = {};

    return {
      state,
      actions,
      bickyPin,
    };
  },
};
</script>

<style src="./style.css" scoped></style>
